<template>
  <el-container>
    <!--    <el-header class="top_div">-->
    <!--      <div class="search_div_father">-->
    <!--        <common-header title="VGM发送"></common-header>-->
    <!--      </div>-->
    <!--    </el-header>-->
    <el-container class="main_center_div" v-if="checkPer(['VGM'])">
      <el-aside class="left_div" width="10%">
        <el-menu :router="true" :default-active="$route.path" class="el-menu-vertical-demo"
                 text-color="black" active-text-color="#409eff" id="leftMenu">
          <template v-for="(item,index) in rocketMenuList" v-if="checkPer(['VGM:rocket'])">
            <el-menu-item :index="item.index" v-if="!item.subMenu">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}
                <span v-if="item.num&&item.num>0"
                      class="num_p">{{ item.num }}</span></span>
            </el-menu-item>
            <el-submenu v-else :index="item.index">
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-for="sub in item.subMenu" :key="sub.index" class="f_w_nor" :index="sub.index">
                  {{ sub.title }}
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </template>
          <template v-for="(item,index) in menuList">
            <el-menu-item :index="item.index" v-if="!item.subMenu">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}
                <span v-if="item.num&&item.num>0"
                      class="num_p">{{ item.num }}</span></span>
            </el-menu-item>
            <el-submenu v-else :index="item.index">
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-for="sub in item.subMenu" :key="sub.index" class="f_w_nor" :index="sub.index">
                  {{ sub.title }}
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </template>
        </el-menu>
      </el-aside>
      <el-container style="position: relative">
        <el-main class="main_div" :class="needFixed?'main_fixed':''">
          <div>
            <!--            <ul class="the_nav">-->
            <!--              <li v-for="item in navList" :key="item.id" :class="{active: !(item.id !== menuIndex)}" class="nav_item">-->
            <!--                <a href="#">{{ item.title }} </a>-->
            <!--                <span v-if="item.id !== '0'" class="close_span" @click.stop="closeSpan(item.id, item)"> ×</span>-->
            <!--              </li>-->
            <!--            </ul>-->
            <div class="right_div">
              <keep-alive>
                <router-view @menuShow="menuShow"></router-view>
              </keep-alive>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <VGMAgencyPage v-else></VGMAgencyPage>
  </el-container>
</template>

<script>
import CommonHeader from '@/components/common/CommonHeader'
import bus from "@/utils/bus";
import {getCountNum} from "@/api/vgm";
import VGMAgencyPage from "@/views/VGM/components/VGMAgencyPage.vue";
import NoticeTopBar from "@/views/ShanghaiManifest/components/NoticeTopBar.vue";

export default {
  components: {
    NoticeTopBar,
    VGMAgencyPage,
    CommonHeader
  },
  data() {
    return {
      menuIndex: 0,
      //系统操作员可查看客户舱单并进行操作
      rocketMenuList: [
        {
          index: '/VGM/all',
          icon: 'el-icon-s-order',
          title: '客户VGM列表',
          num: 0
        },
      ],
      navList: [{
        title: "草稿箱",
        id: '0'
      }],
      menuList: [
        {
          index: '/VGM',
          icon: 'el-icon-s-order',
          title: '草稿箱',
          num: 0
        },
        {
          index: '/VGM/wait',
          icon: 'el-icon-time',
          title: '待发送',
          num: 0
        },
        {
          index: '/VGM/already',
          icon: 'el-icon-s-promotion',
          title: '已发送'
        },
        {
          index: 'VGM_guide',
          icon: 'el-icon-reading',
          title: '用户指南',
          subMenu: [
            {
              index: '/VGM/guide/agreement',
              icon: '',
              title: '用户协议',
            },
            {
              index: '/VGM/guide/book',
              icon: '',
              title: '操作手册',
            },
          ]
        },
        {
          index: 'shanghai_wallet',
          icon: 'el-icon-wallet',
          title: '财务记录',
          subMenu: [
            {
              index: '/pay/chargeHistory',
              icon: '',
              title: '充值记录',
            },
            {
              index: '/pay/accountRecord',
              icon: '',
              title: '消费记录',
            },
            {
              index: '/pay/ticket',
              icon: '',
              title: '开票记录',
            },
          ]
        },
      ],
      offsetTop: 0,
      scrollTop: 0,
    }
  },
  computed: {
    needFixed() {
      if (this.scrollTop > 0) {
        return this.scrollTop >= this.offsetTop - 70;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.offsetTop = document.getElementById("leftMenu").offsetTop;
    window.addEventListener('scroll', this.handleScroll)
    this.init();
  },
  methods: {
    init() {
      this.getCountNum()
      bus.$on('getVGMCount', (data) => {
            this.getCountNum();
          }
      )
    },
    //获取各状态舱单的数量
    getCountNum() {
      const param = {
        showCompanyId: this.$store.state.user.cid ? this.$store.state.user.cid : ''
      }
      getCountNum(param).then((res) => {
        if (res.data.data) {
          this.menuList[0].num = res.data.data.count_draft;
          this.menuList[1].num = res.data.data.count_wait;
        }
      })
    },
    menuShow(path) {
      this.navList.push(path);
      this.menuIndex = path.id;

      this.$router.push({name: path.pathName, params: path.data})
    },
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    }
  },
  beforeDestroy() {
    bus.$off('getVGMCount');
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="stylus" scoped>
.top_div {
  height: auto !important;
  background-image: linear-gradient(var(--RED-C11C20), var(--RED-79031D), var(--RED-68020F));
}

.search_div_father {
  /deep/ .el-input__inner {
    border: none;
    width: auto;
  }
}

.right_div {
  box-sizing: border-box;
}

.left_div {
  background: white;
  box-sizing: border-box;
}

.f_w_nor {
  font-weight: normal;
}

.main_center_div {
  width: 100%;
  height: 100%;
}

.main_div {
  background: var(--GRAY-f1f3f6);
  box-sizing: border-box;
  padding: 0 0 0 1rem;
  width: 100%;
}

.el-menu-vertical-demo {
  font-weight: bold;
  height: 69rem;
  overflow: hidden;
}

.el-menu {
  border-right: solid 1px var(--GRAY-f1f3f6);
}

.el-menu-item.is-active {
  background: var(--GRAY-f1f3f6);
}

.fixed {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  width: 10%;
}

.main_fixed {
  //position: fixed;
  //top: 70px;
}

.the_nav {
  width: 100%;
  margin: 1rem 0 0 0;
  height: 30px;
  background-color: white;

  .nav_item {
    background: var(--GRAY-dcdcdc);
    width: fit-content;
    min-width: 5rem;
    text-align: center;
  }
}

.active {
  background-color: rgba(13, 175, 255, 0.33);
}

ul {
  margin-bottom: 1rem;
  list-style: none;
  line-height: 30px;
}

ul a {
  text-decoration: none;
}

.close_span {
  display: inline-block;
  margin: 0 -1rem 0 1.5rem;
  cursor: pointer;
  padding: 0 1rem;
}

.close_span:hover {
  background-color: lightgrey;
}

.num_p {
  text-align: center;
  display: inline-block;
  min-width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  color: white;
  background: var(--RED-C11C20);
  border-radius: 50%;
  padding: 2px;
  line-height: 1.5;
}
</style>
